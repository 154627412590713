import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdatedBullet,NotifState } from "./types";
import { getToken } from "helper/general";
import axios from "axios";

export const UpdateBulletNotif = createAsyncThunk(
    'pos/change-new-order-status',
    async (props: UpdatedBullet) => {
      const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
            token: getToken()
        },
    };
    try {
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/pos/change-new-order-status`, props ,config);
      if (response && response.data) {
        return {
            data: response.data,
            status: "success",
            error: null,
        } as unknown as NotifState;
      }else{
        return {
            data: response.data?.message,
            status: "empty",
            error: null,
        } as unknown as NotifState;
      }
    } catch (error) {
        return {
            data: "Something went wrong!",
            status: "error",
            error: error,
        } as unknown as NotifState;
    }
  },
  );
