import React from 'react';
import MainLayout from 'components/Layouts/MainLayout';
import {
  Box,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@mui/material';
import { AppLogo } from 'assets/images';
import styled from 'styled-components';
import { NormalText } from 'components/styled/text.styled';
import { ContentContainer } from 'components/styled/layout.styled';
import InputField from 'components/Form/InputField';
import { MailIcon } from 'assets/icons';
import { Button } from 'components/styled/button.styled';
import { useNavigate } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { AuthLogin, AuthLoginProps, AuthData } from 'redux/auth';
import { postOpenPos, OpenPosProps } from 'redux/openPos';
import { validateEmail, notify, setExpirySession } from 'helper/general';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { selectStore } from 'redux/appOption/appoption.reducer';
import CurrencyFormat from 'react-currency-format';

const LoginPage = () => {
  const {
    thunkDispatch,
    storeState: { AppOption },
  } = useRedux();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState<AuthLoginProps>({
    email: '',
    password: '',
  });
  const [open, setOpen] = React.useState(false);
  const [selectStoreModal, setSelectStoreModal] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [optionStore, setOptionStore] = React.useState('');
  const [submit, setSubmit] = React.useState(false);
  const [profile_data, setProfileData] = React.useState<AuthData>();
  const [nominalPos, setNominalPos] = React.useState('');

  const msg = 'login sukses';

  const handleLogin = (e: any) => {
    e.preventDefault();
    setSubmit(true);
    setLoading(true);
    if (payload.email && validateEmail(payload.email) && payload.password) {
      thunkDispatch(AuthLogin(payload))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            notify('success', 'Login Sukses');
            setStatus('success');
            setExpirySession()
            setProfileData(res.data);
            localStorage.setItem('login', msg) 
            if (
              res.data &&
              res.data.profile_data &&
              res.data.profile_data.is_open
            ) {
              setTimeout(() => {
                setLoading(false);
                navigate('/');
                localStorage.getItem('token');
                localStorage.getItem('uuid');
              }, 2000);
            } else {
              setLoading(false);
              res.data.profile_data &&
              res.data.profile_data.store_access &&
              res.data.profile_data.store_access.data_store &&
              res.data.profile_data.store_access.data_store.length === 1
                ? setDefaultStore(
                    res.data?.profile_data?.store_access?.data_store[0] ?? null
                  )
                : setSelectStoreModal(true);
            }
          } else if (res && res.status === 'error') {
            setLoading(false);
            setStatus('error');
            notify('error', res.error.response.data.message);
          }
        });
    } else {
      setStatus('error');
      setLoading(false);
    }
  };

  const setDefaultStore = (item: any) => {
    if (item) {
      let props: { id: number; name: string } = {
        id: Number(item.store_id),
        name: item.store_name,
      };
      thunkDispatch(selectStore(props));
    }
    setSelectStoreModal(false);
    setOpen(true);
  };

  const handleChange = () => {
    let props: { id: number; name: string } = {
      id: Number(optionStore.split('@')[0]),
      name: optionStore.split('@')[1],
    };
    thunkDispatch(selectStore(props));
    setSelectStoreModal(false);
    setOpen(true);
  };

  const postPos = () => {
    setLoading(true);
    let data = {
      store_id: AppOption?.selected_store?.id ?? '',
      user_merchant_id:
        profile_data?.profile_data?.store_access?.user_merchant_id ?? '',
      start_total_amount: nominalPos,
      token: profile_data?.access_token ?? '',
    };
    if (payload.email && validateEmail(payload.email) && payload.password) {
      thunkDispatch(postOpenPos(data as OpenPosProps))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            notify('success', 'Berhasil Opening POS');
            setLoading(false);
            setOpen(false);
            navigate('/');
          } else if (res && res.status === 'error') {
            setLoading(false);
            notify('error', res.error.response.data.message);
          }
        });
    } else {
      setOpen(false);
      setLoading(false);
    }
  };

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <MainLayout background="#FFE39D">
      <Box width={'100%'}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {status &&
          status === 'success' &&
          profile_data &&
          profile_data.user_type !== 'merchant' ? (
            profile_data.profile_data &&
            profile_data.profile_data.store_access &&
            profile_data.profile_data.store_access.data_store &&
            profile_data.profile_data.store_access.data_store.length > 1 ? (
              <Modal
                open={selectStoreModal}
                onClose={() => null}
                sx={{
                  justifyContent: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  top: '10%',
                }}
              >
                <Box
                  textAlign={'center'}
                  sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    maxWidth: '360px',
                    maxHeight: '500px',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                    borderRadius: '20px',
                    flexDirection: 'column',
                    padding: '10px 10px',
                  }}
                >
                  <NormalText
                    fontSize={'20px'}
                    fontWeight={'bold'}
                    margin={'20px 0'}
                  >
                    Hi, {profile_data?.profile_data?.full_name ?? ''}
                  </NormalText>
                  <FormControl focused>
                    <NormalText
                      fontSize={'16px'}
                      margin={'10px 0'}
                      textAlign={'center'}
                    >
                      Pilih Toko
                    </NormalText>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={optionStore}
                      onChange={(e) => {
                        console.log('STRE', e.target.value);
                        setOptionStore(e.target.value);
                      }}
                    >
                      {status === 'success' &&
                        profile_data &&
                        profile_data.profile_data &&
                        profile_data.profile_data.store_access &&
                        profile_data.profile_data.store_access.data_store &&
                        profile_data.profile_data.store_access.data_store
                          .length > 0 &&
                        profile_data.profile_data.store_access.data_store.map(
                          (item, index) => (
                            <FormControlLabel
                              key={'pickstore-' + index}
                              value={item.store_id + '@' + item.store_name}
                              control={
                                <Radio classes={{ checked: '#FFC535' }} />
                              }
                              label={item.store_name}
                              sx={{
                                width: '100%',
                                height: '50px',
                                margin: '5px 0',
                                background:
                                  optionStore.split('@')[1] === item.store_name
                                    ? '#FFC535'
                                    : 'white',
                                borderRadius: '8px',
                                border: '1px solid #FFC535',
                              }}
                            />
                          )
                        )}
                    </RadioGroup>
                  </FormControl>
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    spacing={1}
                    margin={'20px 0 0 0'}
                  >
                    <Button variant={'primary'} onClick={() => handleChange()}>
                      Masuk
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => setSelectStoreModal(false)}
                    >
                      Keluar
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            ) : null
          ) : null}
          <Modal
            open={open}
            onClose={() => null}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
              height: '300px',
              top: '60%',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'white',
                width: '100%',
                position: 'relative',
                maxWidth: '360px',
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                bottom: 0,
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <NormalText
                  textAlign={'center'}
                  fontSize={'20px'}
                  fontWeight={'bold'}
                  lineHeight={'24px'}
                  margin={'0px 0 40px 20px'}
                >
                  Hi, {profile_data?.profile_data?.full_name ?? ''}
                </NormalText>
                <IconButton
                  sx={{
                    width: '30px',
                    height: '30px',
                    position: 'absolute',
                    top: '6px',
                    right: '6px',
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <NormalText fontSize={'12px'}>Opening POS *</NormalText>
                <CurrencyFormat
                  customInput={InputField}
                  thousandSeparator={true}
                  value={nominalPos}
                  onValueChange={(e: any) => {
                    setNominalPos(e.value);
                  }}
                  placeholder={'Masukan nominal uang "OpeningPOS"'}
                />
                <Button
                  width={'100%'}
                  variant="primary"
                  margin="50px 0 0 0"
                  onClick={() => postPos()}
                >
                  Selanjutnya
                </Button>
              </div>
            </Box>
          </Modal>
          <StyledBox>
            <StyledContainter>
              <img src={AppLogo} alt={'App Logo'} />
            </StyledContainter>
          </StyledBox>
          <ContentContainer>
            <NormalText
              textAlign={'center'}
              fontSize={'20px'}
              fontWeight={'bold'}
              lineHeight={'24px'}
              margin={'30px 0 40px 0'}
            >
              Login
            </NormalText>
            <form onSubmit={handleLogin} noValidate>
              <Stack direction={'column'} spacing={2}>
                <InputField
                  inputIcon={MailIcon}
                  label={'Email'}
                  required
                  type={'email'}
                  onChange={(e: any) =>
                    setPayload({ ...payload, email: e.target.value })
                  }
                  validationText={'Silahkan masukan email yang valid'}
                  validationType={
                    (submit && !validateEmail(payload.email)) ||
                    (submit && !payload.email)
                      ? 'error'
                      : undefined
                  }
                  placeholder={'Masukan Email Anda'}
                />
                <InputField
                  inputIcon={'LockIcon'}
                  label="Password"
                  required
                  validationText={
                    submit && !payload.password
                      ? 'Silahkan masukan password anda'
                      : ''
                  }
                  validationType={
                    submit && !payload.password ? 'error' : undefined
                  }
                  type={'password'}
                  onChange={(e: any) =>
                    setPayload({ ...payload, password: e.target.value })
                  }
                  placeholder="Masukan Password Anda"
                />
                <NormalText
                  textAlign={'right'}
                  fontSize={'13px'}
                  color={'#838383'}
                  isPressable
                  title="Lupa password"
                  onClick={() => navigate('/forgot-password')}
                >
                  Lupa Password?
                </NormalText>
              </Stack>
              <Button
                type="submit"
                width={'100%'}
                variant={loading ? 'secondary' : 'primary'}
                margin="35px 0 0 0"
              >
                {loading ? 'Tunggu Sebentar' : 'Login'}
              </Button>
            </form>
            <NormalText
              fontSize="14px"
              textAlign={'center'}
              letterSpacing={'0.1px'}
              margin={'90px 0 0 0'}
            >
              2023 © All Reserved by Pagii Ordering
            </NormalText>
          </ContentContainer>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const StyledBox = styled(Box)`
  height: 250px;
  position: relative;
`;

const StyledContainter = styled(Box)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default LoginPage;
