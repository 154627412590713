export const firebaseConfig = {
 apiKey: "AIzaSyDm0brjRi-8V6fa98XFdNfCGBLXVeBmF_k",
authDomain: "first-cloud-messaging-d0d2b.firebaseapp.com",
projectId: "first-cloud-messaging-d0d2b",
storageBucket: "first-cloud-messaging-d0d2b.appspot.com",
messagingSenderId: "558789874172",
appId: "1:558789874172:web:f1f1e7f0324be813ece279",
measurementId: "G-W201GNG96F"
};

export const pairKey ="BOE7Ja6_LVICtLRcUs14e4wWA4lHUT0knp-jcFmM7wSlscDEVNw1bxeDrz2_etgkI-biQVNPe3GRZ3uK4acPfFQ";
export const serverKey ="AAAAghp40fw:APA91bGE-fuXPVinn0QdKLNfawr2CVu542bwV8Z_0VBZuKAF5bFfcOhnK-ccbjoHuog2sIpLfck83LyqW_7MvHarqLex6gJ5SteZk-QrLloOA_DyD8y3g3S6oBKYiCfe1hyQZzzEMDta";
